// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-luxo-js": () => import("./../src/pages/projects/luxo.js" /* webpackChunkName: "component---src-pages-projects-luxo-js" */),
  "component---src-pages-projects-me-js": () => import("./../src/pages/projects/me.js" /* webpackChunkName: "component---src-pages-projects-me-js" */),
  "component---src-pages-projects-tufas-js": () => import("./../src/pages/projects/tufas.js" /* webpackChunkName: "component---src-pages-projects-tufas-js" */),
  "component---src-pages-success-message-js": () => import("./../src/pages/successMessage.js" /* webpackChunkName: "component---src-pages-success-message-js" */)
}

